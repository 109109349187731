import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import './fileEmbed.scss';

const FileEmbed = ({data}) => {

  return (
    <>
      <div class="content-file-embed">
        <a href={process.env.GATSBY_API_URL + data.file.url} target="_blank">
          <p>
            <FontAwesomeIcon icon={faFileAlt} />
            {data.file_name}
          </p>
        </a>
      </div>
    </>
  )
}

export default FileEmbed;
