import * as React from 'react';
import { Link } from 'gatsby';
import Markdown from './../markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import './blockLink.scss';

const BlockLink = ({data}) => {

  return (
    <>
      <div class="content-blocklink">
        {/* Check if internal or external link */}
        { data.url.substring(0,1) === "/" ?
          <a href={data.url} >
            <p>
              <FontAwesomeIcon icon={faLink} />
              {data.link_text}
            </p>
          </a> :
          <a href={data.url} target="_blank">
            <p>
              <FontAwesomeIcon icon={faLink} />
              {data.link_text}
            </p>
          </a>
        }
        
      </div>
    </>
  )
}

export default BlockLink;
