import * as React from 'react';
import './subheading.scss';

const Subheading = ({data}) => {

  return (
    <>
      <h3 class="subheading">{data.Subheading}</h3>
    </>
  )
}

export default Subheading;
