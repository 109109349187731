import * as React from 'react';

// Content type components
import BasicText from './basicText';
import BlockLink from './blockLink';
import Dropdown from './dropdown';
import FileEmbed from './fileEmbed';
import HighlightBox from './highlightBox.jsx';
import ImagePlusText from './imagePlusText';
import Quote from './quote';
import Subheading from './subheading';
import WideImage from './wideImage';

const Content = ({data}) => {
  
  return (
    <>
      { 
        data.map((content, index) => {
          let key = content.__typename || content.strapi_component || '';
          // Loop through content types
          return <>
            <div class="content-block" key={index}>
              {(() => { 
                switch (key) {
                  case "content.basic-text":
                  case "ComponentContentBasicText":
                    return <BasicText data={content} />;
                      
                  case "content.blocklink":
                  case "ComponentContentBlocklink":
                    return <BlockLink data={content} />;

                  case "content.dropdown":
                  case "ComponentContentDropdown":
                    return <Dropdown data={content} index={index} />;
                  
                  case "content.file-embed":
                  case "ComponentContentFileEmbed":
                    return <FileEmbed data={content} />;
                  
                  case "content.highlight-box":
                  case "ComponentContentHighlightBox":
                    return <HighlightBox data={content} />;
                    
                  case "content.image-text":
                  case "ComponentContentImageText":
                    return <ImagePlusText data={content} />;

                  case "content.quote":
                  case "ComponentContentQuote":
                    return <Quote data={content} />;
                    
                  case "content.subheading":
                  case "ComponentContentSubheading":
                    return <Subheading data={content} />;
                    
                  case "content.wide-image":
                  case "ComponentContentWideImage":
                    return <WideImage data={content} />;

                default:
                  break;
              }})()}
            </div>
          </>
        })
      }
    </>
  )
}

export default Content;