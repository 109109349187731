import * as React from 'react';
import './imagePlusText.scss'
const ImagePlusText = ({data}) => {
  const extractUrl = (image) => (
    process.env.GATSBY_API_URL + (image.formats?.large?.url ?? image.url)
  )

  return (
    <>
      <div class={`image-text ${data.image_position == "left" ? "" : "image-right"}` }>
        <img src={extractUrl(data.image)}/>
        <p>{data.text}</p>
      </div>
    </>
  )
}

export default ImagePlusText;
