import * as React from 'react';
import Markdown from './../markdown';

import './highlightBox.scss';

const HighlightBox = ({data}) => {

  return (
    <>
      <div class="alert alert-primary content-highlight-box" role="alert">
        <Markdown>{data.HighlightBox}</Markdown>
      </div>
    </>
  )
}

export default HighlightBox;
