import React from 'react';
import { navigate } from 'gatsby';

import { useAuth } from './../context/AuthContext';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { checkAuthentication } = useAuth();

  if (checkAuthentication() === false && location.pathname !== `/members/login`) {
    navigate("/members/login");
    return null;
  }

  return <Component {...rest} />
}

export default PrivateRoute;