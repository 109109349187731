import * as React from 'react';
import Markdown from './../markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './dropdown.scss';

const Dropdown = ({data, index}) => {

  return (
    <>
      <div class="content-dropdown">
        <h3
          class="dropdown-heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${index}`}
          aria-expanded="false"
          aria-controls={`collapse-${index}`}
        >
          {data.heading}
          <FontAwesomeIcon className="icon-down" icon={faAngleDown} />
          <FontAwesomeIcon className="icon-up" icon={faAngleUp} />
        </h3>
        <div class="collapse" id={`collapse-${index}`}>
          <div class="content-text"><Markdown>{data.text}</Markdown></div>
        </div>
      </div>
    </>
  )
}

export default Dropdown;
