import * as React from 'react';
import './wideImage.scss';

const WideImage = ({data}) => {
  const extractUrl = (image) => (
    process.env.GATSBY_API_URL + (image.formats?.large?.url ?? image.url)
  )

  return (
    <>
      <div className="content-wide-image">
        <img src={extractUrl(data.image)} alt={data.caption ?? ""} />
        {/* { data.caption && <p>{data.caption}</p> } */}
      </div>
    </>
  )
}

export default WideImage;
