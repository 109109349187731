import React, { useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import Seo from './../seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from './../../context/AuthContext';

import './auth.scss';

const Login = () => {
  const auth = useAuth();

  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const login = async (e) => {
    e.preventDefault();
    let success = await auth.login(identifier, password);
    // if (success) navigate(-1); 
  }

  const getContent = async () => {
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query: `{
            branding {
              login_background {
                url
              }
              logo {
                url
              }
            }
          }`
        })
      }
    );
    
    const data = await response.json();

    setBackgroundImageUrl(data.data.branding.login_background.url);
    setLogoUrl(data.data.branding.logo.url);
  }
  
  useEffect(() => {
    setAuthenticated(auth.authenticated);
  }, [auth.authenticated]);

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <Seo title="Login" />
      {authenticated ? (() => navigate(-1))() : null}
      <div id="login">
        <div class="login-background-image" style={{backgroundImage: `url("${process.env.GATSBY_API_URL + backgroundImageUrl}")`}} />
        <div class="login-form-wrapper">
          <div class="login-form">
            <div class="login-return">
              <a class="link-primary" onClick={()=>navigate(-2)}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;Back
              </a>
            </div>
            <img class="login-logo" src={process.env.GATSBY_API_URL + logoUrl} />
            <p class="login-description">Members Site Login</p>
            <form onSubmit={login}>
              <input class="form-control" required placeholder="Username" type="text" value={identifier} onChange={(e)=>setIdentifier(e.target.value)} />
              <input class="form-control" required placeholder="Password" type="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
              { auth.error ?
                <p class="form-error">Username or password incorrect</p> :
                null
              }
              <input
                class={`btn btn-primary login-button`}
                disabled={auth.loading}
                type="submit"
                value="Login"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    navigate('/');
  }, []);

  return <>
    <Seo title="Logging out" />
  </>;
}

export {
  Login,
  Logout
}
