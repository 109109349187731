import * as React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import Navbar from './navbar';
import Footer from './footer';
import './../styles/layout.scss';
import './../styles/bootstrap.scss';

const Layout = ({ children, before, after }) => {
  const page = useLocation().pathname;
  return (
    <>
      <Helmet>
        <script defer data-domain="medcoaches.uk" src="https://themis.robey.dev/js/script.js"></script>
      </Helmet>
      <Navbar />
      {before}
      <div id="content" class="full-height-wrapper">{children}</div>
      {after}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
