import React, { useState, useEffect } from 'react';

import Layout from './../layout';
import Seo from './../seo';

import { useAuth } from '../../context/AuthContext';
import Content from './../content/';


const MembersContent = ({page}) => {
  const { checkAuthentication } = useAuth();
  const [pageData, setPageData] = useState({});
  const [notFound, setNotFound] = useState(false);

  const getData = async (token) => {
    if (token === false || token === '') return null;

    const response = await fetch(
      `${process.env.GATSBY_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `{
            members(where: {url: "/${page}"}) {
              title
              url
              content {
                __typename
                ... on ComponentContentQuote {
                  date
                  quote
                  attribution
                  attribution_role
                }
                ... on ComponentContentBasicText {
                  basicText: text
                }
                ... on ComponentContentImageText {
                  text
                  image {
                    url
                  }
                  image_position
                }
                ... on ComponentContentWideImage {
                  image {
                    url
                  }
                  caption
                }
                ... on ComponentContentHighlightBox {
                  HighlightBox 
                }
                ... on ComponentContentFileEmbed {
                  file_name
                  file {
                    url
                  }
                }
                ... on ComponentContentSubheading {
                  Subheading
                }
                ... on ComponentContentDropdown {
                  text
                  heading
                }
                ... on ComponentContentBlocklink {
                  link_text
                  url
                }
              }
            }
          }`
        })
      }
    );

    const data = await response.json(); 

    if (data.data.members.length === 0) {
      setNotFound(true);
    } else {
      setPageData(data.data.members[0]);
    }
  }

  useEffect(() => {
    // Make authenticated API call
    const token = checkAuthentication();
    getData(token);
  }, []);

  return <>
    <Layout>
      <Seo title={pageData.title || "Members"} />

      {/* 404 Fallback */}
      { notFound ? <>
        <p>route not found</p>
      </> : null}

      <h1>{pageData.title}</h1>

      {/* Page content */}
      {
        ( 
        pageData.constructor === Object &&
        Object.keys(pageData).length) > 0 ? <>
          <Content data={pageData.content} />
        </> : null
      }

    </Layout>
  </>;
}

export default MembersContent;