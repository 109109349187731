import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

import './quote.scss';

const Quote = ({data}) => {

  return (
    <>
      <div class="quote-wrapper">

        {data.date ? <p class="quote-date">{data.date}</p> : null}
        
        <div class="quote-content">
          <p class="quote-text"><FontAwesomeIcon icon={faQuoteLeft} />{data.quote}</p>
          <p class="quote-attribution">{data.attribution}</p>
          {data.date ? <p class="quote-role">{data.attribution_role}</p> : null}
        </div>
      </div>
    </>
  )
}

export default Quote;
