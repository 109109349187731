import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../../components/PrivateRoute";
import { Login, Logout } from './../../components/members/auth';
import MembersContent from './../../components/members/content';

const Members = () => {

  return <>
      <Router basepath="/members">
        <Login path="/login" />
        <Logout path="/logout" />
        <PrivateRoute path="/:page" component={MembersContent} />
        <PrivateRoute path="/" component={MembersContent} page="" />
      </Router>
  </>
}
export default Members;